<template>
    <el-form ref="incomeStatementData" class="card-tail" label-position="top" v-loading="$waiting.is('loading')">
        <MissingSruAccountsInfo
            :financial-data="incomeStatementData"
            :client-id="clientId"
            :client-info="clientInfo"
            :annual-report-id="annualReportId"
            @create-income-statement-data="createIncomeStatementData"
            @update-income-statement-details="getIncomeStatementDetails"
            @update-is-displaying-accounts="updateIsDisplayingAccounts"
            :financial-page="financialPage"
        />
        <div class="flex flex-row">
            <div class="p-5 flex" style="width: 46vw">
                <ModalInspectIncomeStatement
                    ref="inspectModal"
                    :visible="modalIncomeStatementVisible"
                    :client-id="clientId"
                    :client-info="clientInfo"
                    :income-statement-data="incomeStatementData"
                    :result-data="resultData"
                    @close="modalIncomeStatementVisible = false"
                />
                <ResultTable :result-data="resultData" :income-statement-data="incomeStatementData" @display-accounts="displayAccounts" />
            </div>
            <AccountsSection
                :is-displaying-accounts="isDisplayingAccounts"
                :client-id="clientId"
                :client-info="clientInfo"
                :selected-accounts="selectedAccounts"
                :income-statement-data="incomeStatementData"
                :titles-data="titlesData"
                :annual-report-id="annualReportId"
                @show-move-account-modal="showMoveAccountModal()"
                @update-income-statement-details="getIncomeStatementDetails"
                @update-is-displaying-accounts="updateIsDisplayingAccounts"
                :is-period="isPeriod"
            />
        </div>
        <ActionButtons @go-to-page-before="goToPageBefore" @show-inspect="showInspectIncomeStatement" @go-to-next-page="goToNextPage" />
    </el-form>
</template>

<script>
import Api from "../annualReport.api";

export default {
    name: "IncomeStatementForm",
    components: {
        ModalInspectIncomeStatement: () => import("./components/InspectPdf.vue"),
        ResultTable: () => import("./components/ResultTable.vue"),
        AccountsSection: () => import("./components/AccountsSection.vue"),
        ActionButtons: () => import("../components/ActionButtons.vue"),
        MissingSruAccountsInfo: () => import("./components/MissingSruAccountsInfo.vue"),
    },
    props: {
        annualReportId: {
            type: String,
            required: true,
        },
        clientInfo: {
            type: Object,
        },
        clientIdProp: {
            type: String,
            default: undefined,
        },
    },
    data() {
        return {
            clientId: null,
            incomeStatementData: {},
            modalIncomeStatementVisible: false,
            isDisplayingAccounts: false,
            resultData: [],
            selectedAccounts: [],
            titlesData: [],
            isPeriod: false,
            isResetModalVisible: false,
            financialPage: "resultaträkningen",
        };
    },
    async created() {
        this.clientId = this.clientIdProp;
        await this.getIncomeStatementDetails();
    },
    methods: {
        async getIncomeStatementDetails() {
            this.$waiting.start("loading");
            let data = await Api.getIncomeStatementDetails(this.annualReportId);
            if (Object.keys(data).length > 0) {
                this.incomeStatementData = data;
            }
            await this.createIncomeStatementDetails(data);
            this.resultData = this.filterData(this.incomeStatementData, row => [1, 5, 3].includes(row.type) && (row.period !== 0 || row.previousPeriod !== 0));
            this.titlesData = this.titleAccounts(5);

            this.$waiting.end("loading");
        },
        async createIncomeStatementDetails(data) {
            let newData = await Api.createIncomeStatement(this.annualReportId, this.clientId, this.clientInfo.financialYearStart, this.clientInfo.financialYearEnd, false);
            if (Object.keys(newData).length > 0) {
                this.incomeStatementData = newData;
                if (Object.keys(data).length > 0) {
                    this.$notify.info({ title: "Ändringar i bokföringen har skett, resultaträkning är återställd" });
                }
            }
        },
        filterData(data, condition) {
            if (data && data.row) {
                return data.row.filter(condition);
            }
            return [];
        },
        displayAccounts(row, isPeriod) {
            this.$waiting.start("loading");
            this.isPeriod = isPeriod;
            if (!this.isNameUppercase(row)) {
                if (this.isDisplayingAccounts === false) {
                    this.isDisplayingAccounts = true;
                }
                this.selectedAccounts = this.filteredAccounts(row.sruCode);
            }
            this.$waiting.end("loading");
        },
        goToNextPage() {
            this.$router.push(`/annualReport/balanceSheet/${this.annualReportId}`);
        },
        goToPageBefore() {
            this.$router.push(`/annualReport/managementReport/${this.annualReportId}`);
        },
        showInspectIncomeStatement() {
            this.modalIncomeStatementVisible = true;
            this.$nextTick(() => {
                this.$refs.inspectModal.$el.focus();
            });
        },
        updateIsDisplayingAccounts(newValue) {
            this.isDisplayingAccounts = newValue;
        },
        async createIncomeStatementData(newData) {
            this.incomeStatementData = newData;
        },
    },
    computed: {
        isNameUppercase() {
            return row => {
                if (row && row.name && typeof row.name === "string") {
                    return row.name === row.name.toUpperCase();
                } else {
                    return false;
                }
            };
        },
        filteredAccounts() {
            return sruCode => {
                return this.incomeStatementData.row.filter(row => row.sruCode === sruCode);
            };
        },
        titleAccounts() {
            return type => {
                return this.incomeStatementData.row.filter(row => row.type === type && row.name != row.name.toUpperCase());
            };
        },
    },
};
</script>
